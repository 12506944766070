import React, { useState } from 'react';
import './App.css';

function App() {
  const [result, setResult] = useState('');

  function generateId(size = 10) {
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var randomString = '';
    for (var i = 0; i < size; i++) {
        var randomIndex = Math.floor(Math.random() * characters.length);
        randomString += characters[randomIndex];
    }

    return randomString;
  }

  const generateDiscord = () => {
    // Genera un número aleatorio de 10 dígitos
    const randomLongNumber = "0ce65cec2ac35c352d6a10308d06f19c58abbbe654037f5c622ac7" + generateId(10);
  
    // Prepara el cuerpo de la solicitud
    const body = {
      partnerUserId: randomLongNumber.toString()
    };
  
    // Realiza la solicitud POST
    fetch('https://api.discord.gx.games/v1/direct-fulfillment', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body)
    })
    .then(response => response.json())
    .then(response => {
      setResult("https://discord.com/billing/partner-promotions/1180231712274387115/" + response['token']);
    })
    .catch((error) => {
      console.error('Error:', error);
    });
  };

  return (
    <div className="App">
      <h1 className="Title">Discord Nitro Generator</h1>
      <div className="Instructions">
          <p>Para que esto funcione, debes instalar en tu PC una extensión para deshabilitar CORS. No te olvides de desinstalar la extensión cuando tengas tu Discord Nitro.</p>
          <a href="https://chromewebstore.google.com/detail/lhobafahddgcelffkeicbaginigeejlf?hl=es" rel="noreferrer" target="_blank" className="Link">Extensión para Chrome / Brave / Opera</a>
          <a href="https://addons.mozilla.org/es/firefox/addon/access-control-allow-origin/" rel="noreferrer" target="_blank" className="Link">Extensión para Firefox</a>
      </div>
      <header className="App-Body">
        <button className="Button-Generator" onClick={generateDiscord}>Generate</button>
        <a href={result} rel="noreferrer" target="_blank" className="Result">{"Enlace de nitro: " + result}</a>
      </header>
      <footer className="App-Footer">
        <p className="Footer-Text">Made by <a href="https://goedix.live" rel="noreferrer" target="_blank" className="Footer-Link">Goedix</a> follow me on <a rel="noreferrer" target="_blank" href="https://twitter.com/NGoedix">Twitter (X)</a></p>
      </footer>
    </div>
  );
}

export default App;
